import React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from '../components/layouts/MainLayout'

export default function SlabDetailPageTemplate({pageContext, data}) {
  //const {slabId, slug} = pageContext
  const {name, shortDescription, description, sku, price, image, galleryImages, seo} = data.wpSimpleProduct
  const materialName = name.replace('-', '')
  const categories = data.wpSimpleProduct.productCategories.nodes
  const installImages = galleryImages.nodes
  const {title, metaDesc} = seo
  const {company, siteUrl} = data.site.siteMetadata
  const imageArr = () => {
    const {nodes} = galleryImages
    let array = [`${siteUrl}${image.localFile.publicURL}`]
    nodes.forEach( node => {
      array.push(`${siteUrl}${node.localFile.publicURL}`)
    });
    return array
  }

  const metaData = {
    title: title || `Find ${name} at ${company}`,
    description: metaDesc || `${name} - ${shortDescription.replace( /(<([^>]+)>)/ig, '')}` || `${name} - ${description.replace( /(<([^>]+)>)/ig, '')}`,
    image: image.publicURL,
    product: {
      name: name,
      sku,
      price,
      imageArr: imageArr() 
    }
  }

  
  return (
    <Layout metaData={metaData}>
      <section className="bg-white overflow-hidden">
        <div className="relative max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
          <div className="hidden lg:block bg-gray-50 absolute top-0 bottom-0 left-3/4 w-screen"></div>
          <div className="mx-auto text-base max-w-prose lg:max-w-none">
            {categories.map(({name}) => (
              <h2 className="text-base text-secondary-600 font-semibold tracking-wide uppercase">{name}</h2>
            ))}
            <h1 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">{materialName}</h1>
          </div>
          <div className="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
            <div className="relative lg:row-start-1 lg:col-start-2">
              <svg className="hidden lg:block absolute top-0 right-0 -mt-20 -mr-20" width="404" height="384" fill="none" viewBox="0 0 404 384" aria-hidden="true">
                <defs>
                  <pattern id="de316486-4a29-4312-bdfc-fbce2132a2c1" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                    <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
                  </pattern>
                </defs>
                <rect width="404" height="384" fill="url(#de316486-4a29-4312-bdfc-fbce2132a2c1)" />
              </svg>
              <div className="relative text-base mx-auto max-w-prose lg:max-w-none">
                <figure>
                  <div className="aspect-w-12 aspect-h-7 lg:aspect-none">
                    <GatsbyImage
                      className="rounded-lg shadow-lg object-cover object-center"
                      image={image.localFile.childImageSharp.gatsbyImageData}
                      alt={`${name} Slab Image`}
                    />
                  </div>
                  <figcaption className="mt-3 flex text-sm text-gray-500">
                    <svg className="flex-none w-5 h-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd" d="M4 5a2 2 0 00-2 2v8a2 2 0 002 2h12a2 2 0 002-2V7a2 2 0 00-2-2h-1.586a1 1 0 01-.707-.293l-1.121-1.121A2 2 0 0011.172 3H8.828a2 2 0 00-1.414.586L6.293 4.707A1 1 0 015.586 5H4zm6 9a3 3 0 100-6 3 3 0 000 6z" clip-rule="evenodd" />
                    </svg>
                    <span className="ml-2">{`${name} Slab Image`}</span>
                  </figcaption>
                </figure>
              </div>
            </div>
            <div className="mt-8 lg:mt-0">
              <div className="text-base max-w-prose mx-auto lg:max-w-none">
                <div dangerouslySetInnerHTML={{__html: shortDescription }} />
              </div>
              <div className="mt-5 prose prose-primary text-gray-500 mx-auto lg:max-w-none lg:row-start-1 lg:col-start-1">
                <div dangerouslySetInnerHTML={{__html: description }} />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div class="container max-w-7xl mx-auto px-4"> 
          {installImages.length > 0 ? 
            <div>
              <h2 className="my-8 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">Install Images for {materialName}</h2>
              <div className="text-base max-w-prose mx-auto lg:max-w-none">
                <div dangerouslySetInnerHTML={{__html: metaDesc }} />
              </div>
            </div>
            : null

          }
          <div class="grid md:grid-cols-2 gap-4">
            {/* <pre>
              { JSON.stringify(installImages, null, 2) }
            </pre> */}
              {installImages.map( imageData => (
                <figure class="p-8">
                  <GatsbyImage
                    className="rounded shadow-md object-cover object-center"
                    image={imageData.localFile.childImageSharp.gatsbyImageData}
                    alt={imageData.altText || `${name} (install picture)`}
                    />
                    <figcaption className="mt-3 flex text-sm text-gray-500">
                      <span className="ml-2">{imageData.altText || `${name} (install picture)`}</span>
                    </figcaption>
                  </figure>
                  
                ))}
          </div>
        </div>
      </section>

    </Layout>
  )
}

export const query = graphql`
  query($slabId: String!) {
    site {
      siteMetadata {
        company
        siteUrl
      }
    }
    wpSimpleProduct(id: { eq: $slabId }) {
      name
      description
      shortDescription
      sku
      price
      productCategories {
        nodes {
          name
        }
      }
      seo {
        title
        metaDesc
      }
      image {
        altText
        localFile {
          publicURL
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
      }
      galleryImages {
        nodes {
          altText
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
      }
    }
  }
`
